(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
  const menuTrigger = document.querySelector('.main-menu-trigger');
  const navigation = document.querySelector('.main-navigation');
  const navigationItemsWithChildren = document.querySelectorAll('.main-navigation__item--with-children > .main-navigation__link');

  let mobile = null;
  let isTouchableDevice = false;

  function handleDOMContentLoaded() {
    menuTrigger.addEventListener('click', handleMenuTriggerClick);
    navigationItemsWithChildren.forEach((item) => item.addEventListener('click', handleNavigationItemWithChildren))

    mobile = window.matchMedia('(any-hover: hover) and (pointer: fine');
    handleMenu(mobile)
    mobile.addListener(handleMenu);
  }

  function handleMenuTriggerClick(e) {
    e.preventDefault();

    if (navigation.classList.contains('main-navigation--opened')) {
      navigation.classList.remove('main-navigation--opened');
      e.currentTarget.textContent = 'Menu';
    } else {
      navigation.classList.add('main-navigation--opened');
      e.currentTarget.textContent = 'Chiudi';
    }
  }

  function handleMenu(e) {
    isTouchableDevice = !e.matches;
  }

  function handleNavigationItemWithChildren(e) {
    if (isTouchableDevice) {
      e.preventDefault();
      const container = e.currentTarget.parentElement.querySelector('.main-navigation__inner');

      if (container.classList.contains('main-navigation__inner--opened')) {
        container.classList.remove('main-navigation__inner--opened');
      } else {
        container.classList.add('main-navigation__inner--opened');
      }
    }
  }
})();
